<template>
  <div class="travellers">
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <h3 class="title mb-30 cgray f13">
          <a-row>
            <a-col :span="12"> LISTA DE TERCEIROS PAGANTES </a-col>
            <a-col class="a-right" :span="12">
              <a-button
                class="f12"
                v-if="third_party_payment_rows.length < 10"
                size="small"
                @click="addRow(third_party_payment_rows.length)"
                type="link"
              >
                Adicionar
              </a-button>
            </a-col>
          </a-row>
        </h3>

        <a-row
          v-for="travellers in third_party_payment_rows"
          :key="travellers.id"
          :class="`traveller
                            traveller-${travellers.id}`"
          :gutter="[20, 0]"
        >
          <a-col :span="9">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    'third_party_payment_person_' +
                      travellers.id +
                      '_first_name'
                  )
                    ? 'filled'
                    : ''
                "
                >Nome</label
              >
              <a-input
                class="travel-input"
                placeholder="Nome"
                v-decorator="[
                  'third_party_payment_person_' + travellers.id + '_first_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <template slot="prefix">#{{ travellers.id }}</template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    'third_party_payment_person_' + travellers.id + '_last_name'
                  )
                    ? 'filled'
                    : ''
                "
                >Sobrenome</label
              >
              <span class="travel-input">
                <a-input
                  class="travel-input"
                  placeholder="Sobrenome"
                  v-decorator="[
                    'third_party_payment_person_' +
                      travellers.id +
                      '_last_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    'third_party_payment_person_' + travellers.id + '_CPF'
                  )
                    ? 'filled'
                    : ''
                "
                >CPF</label
              >
              <span class="travel-input">
                <a-input
                  class="travel-input"
                  placeholder="CPF"
                  v-mask="'###.###.###-##'"
                  v-decorator="[
                    'third_party_payment_person_' + travellers.id + '_CPF',
                    {
                      rules: [
                        {
                          required: true,
                          min: 9,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item> </a-col
          ><a-col :span="1" style="text-align: center">
            <a-button
              v-if="
                travellers.id === third_party_payment_rows.length &&
                third_party_payment_rows.length > 1
              "
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeRow(travellers.id)"
            >
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "ThirdPartyForm",
  props: {
    contract: Object,
    form: Object,
    third_party_payment_rows: Array,
  },
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      this.third_party_payment_rows.forEach((person) => {
        this.form.setFieldsValue({
          [`third_party_payment_person_${person.id}_first_name`]:
            this.contract.meta[
              `third_party_payment_person_${person.id}_first_name`
            ],
          [`third_party_payment_person_${person.id}_last_name`]:
            this.contract.meta[
              `third_party_payment_person_${person.id}_last_name`
            ],
          [`third_party_payment_person_${person.id}_CPF`]:
            this.contract.meta[`third_party_payment_person_${person.id}_CPF`],
        });
      });
    }, 500);
  },
  methods: {
    removeRow(id) {
      this.$emit("removeThirdParty", id);
    },
    addRow(id) {
      this.$emit("updateThirdParty", id);
    },
  },
};
</script>
