var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travellers"},[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"title mb-30 cgray f13"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_vm._v(" LISTA DE TERCEIROS PAGANTES ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(_vm.third_party_payment_rows.length < 10)?_c('a-button',{staticClass:"f12",attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.addRow(_vm.third_party_payment_rows.length)}}},[_vm._v("\n              Adicionar\n            ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.third_party_payment_rows),function(travellers){return _c('a-row',{key:travellers.id,class:`traveller
                          traveller-${travellers.id}`,attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":9}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  'third_party_payment_person_' +
                    travellers.id +
                    '_first_name'
                )
                  ? 'filled'
                  : ''},[_vm._v("Nome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'third_party_payment_person_' + travellers.id + '_first_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                'third_party_payment_person_' + travellers.id + '_first_name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome"}},[_c('template',{slot:"prefix"},[_vm._v("#"+_vm._s(travellers.id))])],2)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  'third_party_payment_person_' + travellers.id + '_last_name'
                )
                  ? 'filled'
                  : ''},[_vm._v("Sobrenome")]),_vm._v(" "),_c('span',{staticClass:"travel-input"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'third_party_payment_person_' +
                    travellers.id +
                    '_last_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  'third_party_payment_person_' +\n                    travellers.id +\n                    '_last_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Sobrenome"}})],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  'third_party_payment_person_' + travellers.id + '_CPF'
                )
                  ? 'filled'
                  : ''},[_vm._v("CPF")]),_vm._v(" "),_c('span',{staticClass:"travel-input"},[_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"},{name:"decorator",rawName:"v-decorator",value:([
                  'third_party_payment_person_' + travellers.id + '_CPF',
                  {
                    rules: [
                      {
                        required: true,
                        min: 9,
                        message: 'Preencha todos números.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'third_party_payment_person_' + travellers.id + '_CPF',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        min: 9,\n                        message: 'Preencha todos números.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"CPF"}})],1)])],1),_c('a-col',{staticStyle:{"text-align":"center"},attrs:{"span":1}},[(
              travellers.id === _vm.third_party_payment_rows.length &&
              _vm.third_party_payment_rows.length > 1
            )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeRow(travellers.id)}}}):_vm._e()],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }